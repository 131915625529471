/**************************************************************************************************/

var BrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "Other";
        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
    },
    searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            this.versionSearchString = data[i].subString;

            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index === -1) {
            return;
        }

        var rv = dataString.indexOf("rv:");
        if (this.versionSearchString === "Trident" && rv !== -1) {
            return parseFloat(dataString.substring(rv + 3));
        } else {
            return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
        }
    },

    dataBrowser: [
        {string: navigator.userAgent, subString: "Edge", identity: "Edge"},
        {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
        {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
        {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
        {string: navigator.userAgent, subString: "Opera", identity: "Opera"},  
        {string: navigator.userAgent, subString: "OPR", identity: "Opera"},  

        {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"}, 
        {string: navigator.userAgent, subString: "Safari", identity: "Safari"}       
    ]
};

BrowserDetect.init();

/**************************************************************************************************/

function pageSize() {
    return (window.innerWidth < window.innerHeight ? 'portrait' : 'landscape');
}

/**************************************************************************************************/

function getScrollbarWidth() {
    return (window.innerWidth - $(document).width());
}

/**************************************************************************************************/

function is_touch_device() {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function(query) {
    return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

/**************************************************************************************************/

function detectMouseWheelDirection(e) {
    var delta = null,
        direction = false;
    if (typeof e  === 'undefined') { // if the event is not provided, we get it from the window object
        e = window.event;
    }
    if (e.deltaY) {
        delta = e.deltaY;
    }
    if ( delta !== null ) {
        direction = delta > 0 ? 'up' : 'down';
    }
    return direction;
}

/**************************************************************************************************/

function getCoordinates(o) {

    var r = [];
        spot = o.object[0].getBoundingClientRect(),
        body = document.body,
        html = document.documentElement,
        doc  = document.documentElement.getBoundingClientRect();

    r.spot = spot;

    r.window = [];
    r.window.width = (window.innerWidth || html.clientWidth);
    r.window.height = (window.innerHeight || html.clientHeight);
    r.window.pageXOffset = (window.pageXOffset || html.scrollLeft);
    r.window.pageYOffset = (window.pageYOffset || html.scrollTop);

    r.doc = doc;
    r.doc.height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );

/*
    spot.page = [];
    spot.page.width = doc.width;
    spot.page.height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    spot.page.fromTop = Math.abs(doc.top);
    spot.page.fromBottom = spot.page.height - spot.page.fromTop - doc.height;

    spot.onPage = [];
    spot.onPage.fromTop = Math.abs(spot.top);
    spot.onPage.fromLeft = Math.abs(spot.left);
    spot.onPage.fromBottom = spot.page.height - (spot.onPage.fromTop + spot.height);
    spot.onPage.fromRight = spot.page.width - (spot.onPage.fromLeft + spot.width);
*/

    r.spot.centerFromTop = Math.ceil((r.window.height - r.spot.height) / 2);
    r.spot.centerFromLeft = Math.ceil((r.window.width - r.spot.width) / 2);
    r.spot.from = getPosition(o.object[0]);
    r.spot.scrollTop = r.window.pageYOffset + r.spot.top; // + r.doc.top;
//    spot.scroolTo = spot.top - spot.fromScreenTop;

    r.top = r.spot.top;
    r.left = r.spot.left;
    r.bottom = r.spot.bottom;
    r.right = r.spot.right;

    return r;
}

/**************************************************************************************************/

function getPosition(element) {
    var position = [];

    position.e = element;
    position.top = 0;
    position.left = 0;

    while (element) {
        position.left += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        position.top += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }
    return position;
}

/**************************************************************************************************/
