/**************************************************************************************************/

function whichPrefix(whichEvent) {
	if (typeof whichEvent === 'undefined')
		throwError(whichEvent, 'Event type is required');

	var el = document.createElement('fakeelement');
	var pfx = ['', 'o', 'moz', 'webkit', 'MS'];

	for (var p = 0; p < pfx.length; p++) {
		if (!pfx[p]) whichEvent = whichEvent.toLowerCase();
		var r = pfx[p] + whichEvent;
		if (el.style[r] !== undefined) {
			return r;
		}
	}
}

// Function from David Walsh: http://davidwalsh.name/css-animation-callback
function whichTransitionEvent() {
	var t,
		el = document.createElement('fakeelement');

	var transitions = {
		transition: 'transitionEnd',
		OTransition: 'oTransitionEnd',
		MSTransition: 'msTransitionEnd',
		MozTransition: 'transitionend',
		WebkitTransition: 'webkitTransitionEnd'
	};

	for (t in transitions) {
		if (el.style[t] !== undefined) {
			return transitions[t];
		}
	}
}

var transitionEvent = whichTransitionEvent();
function whichAnimationEvent() {
	var t,
		el = document.createElement('fakeelement');

	var animations = {
		animation: 'animationend',
		OAnimation: 'oAnimationEnd',
		MozAnimation: 'animationend',
		WebkitAnimation: 'webkitAnimationEnd'
	};

	for (t in animations) {
		if (el.style[t] !== undefined) {
			return animations[t];
		}
	}
}

var animationEvent = whichAnimationEvent();

/*
    var anim = document.getElementById("anim"),

    PrefixedEvent(anim, "AnimationStart", AnimationListener);
    PrefixedEvent(anim, "AnimationIteration", AnimationListener);
    PrefixedEvent(anim, "AnimationEnd", AnimationListener);
    PrefixedEvent(anim, "TransitionStart", TransitionListener);
    PrefixedEvent(anim, "TransitionIteration", TransitionListener);
    PrefixedEvent(anim, "TransitionEnd", TransitionListener);
*/

// apply prefixed event handlers
function PrefixedEvent(element, eventName, callback) {
	//    var eventName = whichPrefix(type);

	element.one(eventName, function(e) {
		if (typeof callback === 'function') {
			setTimeout(callback, 1);
		}
	});
}

/**************************************************************************************************/

/**
 * Parallax Scrolling
 * @param {Array} targets - array of selectorstrings which will be targeted for parallax
 * @param {string} selectorClass - classname that will be applied to parallax objects
 * @param {object} options - parallax plugin options
 */
function startParallax(targets = [], selectorClass, options = {}) {
	if (targets.length > 0) {
		targets.forEach((selectorString) => {
			if (!document.querySelectorAll(selectorString)) {
				return;
			}
			document
				.querySelectorAll(selectorString)
				.forEach((singleTargetElementRef) => {
					singleTargetElementRef.classList.add(selectorClass);
				});
		});
	}
	new Rellax(`.${selectorClass}`, options);
}

/**
 * Pan Effect on mouse over
 * @param {string} selector - selector string for container of the images elements to which the pane effect will be applied to
 */

// add pan effect on mouse hover {
function startImagePane(selector) {
	if (!selector && document.querySelectorAll(selector)) {
		return;
	}

	document.querySelectorAll(selector).forEach((element) => {
		const imageRef = element.children[0];

		imageRef.style.transition = 'none';
		imageRef.style.transform = 'scale(1.05)';
		element.style.overflow = 'hidden';

		element.addEventListener('mousemove', (event) => {
			const image = event.target;
			var x =
				((event.pageX - image.offsetLeft) / image.offsetWidth) * 100;
			var y =
				((event.pageY - image.offsetTop) / image.offsetHeight) * 100;

			image.style.transformOrigin = `${x}% ${y}%`;
		});
	});
}

/**
 * Text fade in animation
 * @param {Array} selectorsArray - Array of strings for targeting the elements which will be animated
 */

function startTextFadeIn(selectorsArray) {
	viewportWidth = window.innerWidth;

	if ('IntersectionObserver' in window) {
		// Setup IntersectionObserer - Add active classes via intersectionObserver

		var intersectionObserver = new IntersectionObserver((entry) => {
			entry.forEach((singleEntry) => {
				if (singleEntry.isIntersecting) {
					singleEntry.target.classList.add('animated--active');
					
				}
			});
		});

		// Select Elements and add them to the intersectionObserver
		selectorsArray.forEach((selectorString) => {

			document.querySelectorAll(selectorString).forEach((element) => {
			

				if (element.getBoundingClientRect().left > (viewportWidth * 0.4)) {
					element.classList.add('animated--origin-right');
				}
				element.classList.add('animated');
		
				intersectionObserver.observe(element);
			});
		});
	} else {
		console.log('intersectionObserver is not supported');
	}
}
