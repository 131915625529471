/**************************************************************************************************/

function scrollTo(element, to, duration) {
	var start = element.scrollTop,
		change = to - start,
		currentTime = 0,
		increment = 20

	var animateScroll = function () {
		currentTime += increment
		var val = Math.easeInOutQuad(currentTime, start, change, duration)
		//console.log(val);
		element.scrollTop = val
		if (currentTime < duration) {
			setTimeout(animateScroll, increment)
		}
	}
	animateScroll()
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
	t /= d / 2
	if (t < 1) return (c / 2) * t * t + b
	t--
	return -((c / 2) * (t * (t - 2) - 1)) + b
}

/**************************************************************************************************/

function scrollToTop(scrollDuration) {
	var scrollStep = -window.scrollY / (scrollDuration / 15),
		scrollInterval = setInterval(function () {
			if (window.scrollY != 0) {
				window.scrollBy(0, scrollStep)
			} else {
				clearInterval(scrollInterval)
			}
		}, 15)
}

/**************************************************************************************************/

function pageOnBottom(mBottom) {
	mBottom = typeof mBottom !== 'undefined' ? mBottom : pageFromBottom
	return $(window).scrollTop() + $(window).height() > $(document).height() - mBottom
}
function pageOnTop(mTop) {
	mTop = typeof mTop !== 'undefined' ? mTop : pageFromTop
	return $(window).scrollTop() <= mTop
}
function pageGoTop(callback) {
	$('html, body').animate({ scrollTop: 0 }, 600, 'linear', function () {
		$('body').removeClass('on-bottom').addClass('on-top')
		if (typeof callback !== 'undefined') {
			callback()
		}
	})
}
function pageGoBottom(callback) {
	$('html, body').animate({ scrollTop: $(document).height() }, 600, 'linear', function () {
		$('body').addClass('on-bottom').removeClass('on-top')
		if (typeof callback !== 'undefined') {
			callback()
		}
	})
}

function pageOnScroll(mDelimiter) {
	mDelimiter = typeof mDelimiter !== 'undefined' ? mDelimiter : pageFromTop
	if (pageOnTop(mDelimiter) == true) {
		$('body').removeClass('on-bottom').addClass('on-top')
	} else if (pageOnBottom(mDelimiter) == true) {
		$('body').addClass('on-bottom').removeClass('on-top')
	} else {
		$('body').removeClass('on-bottom').removeClass('on-top')
	}
}

/**************************************************************************************************/

/**
 * Navigation bar Scroll;
 *  for fading in and out fo the navbar on scroll
 */

var navbarScrollingBlocked = false
var scrollYPos = 0
const throttleInMs = 50

function navigatonBarScrolling() {
	const navBarRef = document.querySelector('header')

	window.addEventListener(
		'scroll',
		() => {
			// check if throttled
			if (navbarScrollingBlocked) {
				return
			}
			throttleScrollEvent()

			if (window.scrollY >= 100) {
				if (window.scrollY > scrollYPos) {
					changeNavbarVisility(navBarRef)
					//scrolling up
				} else {
					changeNavbarVisility(navBarRef, true)
				}
			} else {
				removeVisibilityClasses(navBarRef)
			}

			scrollYPos = window.scrollY
		},
		{ passive: true }
	)
}

function throttleScrollEvent() {
	navbarScrollingBlocked = true
	setTimeout(() => {
		navbarScrollingBlocked = false
	}, throttleInMs)
}

function changeNavbarVisility(navBarRef, show = false) {
	if (show) {
		navBarRef.classList.add('navigation--show')
		navBarRef.classList.remove('navigation--hide')
	} else {
		navBarRef.classList.add('navigation--hide')
		navBarRef.classList.remove('navigation--show')
	}
}

function removeVisibilityClasses(navBarRef) {
	navBarRef.classList.remove('navigation--hide')
	navBarRef.classList.remove('navigation--show')
}

/**************************************************************************************************/
