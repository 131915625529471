/**************************************************************************************************/

function throwError(pred, msg) {
	if (!pred) {
		throw new Error(msg)
	}
}

/**************************************************************************************************/

function wait(seconds, callback) {
	return window.setTimeout(callback, seconds * 1000)
}

/**************************************************************************************************/
