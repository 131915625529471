;(function () {
	'use strict'

	Element.prototype.isNodeList = function () {
		return false
	}
	NodeList.prototype.isNodeList = HTMLCollection.prototype.isNodeList = function () {
		return true
	}

	function Q_object(selector) {
		var elements = []
		var length = 0
		if (typeof selector === 'object') {
			if (selector.window === selector) {
				elements.push(selector)
			} else if (typeof selector.length !== 'undefined' && selector.length > 0) {
				for (var i = 0, len = selector.length; i < len; i++) {
					elements.push(selector[i])
				}
			} else if (!Array.isArray(selector)) {
				elements.push(selector)
			}
		} else {
			if (Array.isArray(selector)) {
				for (var i = 0, len = selector.length; i < len; i++) {
					if (typeof selector[i] === 'object') {
						elements.push(selector[i])
					}
				}
			} else {
				if (typeof selector !== 'string') {
					throw '[Q] Given selector is not a string or node: ' + selector
				}

				var tokens = selector.split(',')
				for (var i = 0; i < tokens.length; i++) {
					var token = tokens[i].trim()

					// continue if token is undefined
					if (!token) {
						continue
					}

					var tokenElems = document.querySelectorAll(token)
					for (var j = 0; j < tokenElems.length; j++) {
						elements.push(tokenElems[j])
					}
				}
			}
		}
		this.object = elements
		this.length = elements.length
	}

	Q_object.prototype.get = function (element) {
		if (this.length - 1 >= element) {
			return this.object[element]
		} else {
			throw '[Q] Not to many elements: ' + element
		}
	}

	Q_object.prototype.each = function (callback) {
		for (var i = 0; i < this.object.length; i++) {
			callback.call(this, this.object[i], i)
		}
		return this
	}

	Q_object.prototype.on = function (event, param1, param2) {
		if (typeof param1 === 'function') {
			return this.each(function (e) {
				e.addEventListener(event, param1)
			})
		} else if (typeof param1 === 'string' && typeof param2 === 'function') {
			return this.each(function (e) {
				var obj = q(e).find(param1)
				obj.each(function (f) {
					q(f).on(event, param2)
				})
			})
		}
	}

	Q_object.prototype.off = function (event, handler) {
		return this.each(function (e) {
			e.removeEventListener(event, handler)
		})
	}

	Q_object.prototype.one = function (event, handler) {
		return this.each(function (e) {
			e.addEventListener(event, handler, { once: true })
		})
	}

	Q_object.prototype.addClass = function (name) {
		var i, arr1
		arr1 = name.split(' ')
		return this.each(function (e) {
			for (i = 0; i < arr1.length; i++) {
				e.classList.add(arr1[i])
			}
		})
	}

	Q_object.prototype.removeClass = function (name) {
		var i, arr1
		arr1 = name.split(' ')
		return this.each(function (e) {
			for (i = 0; i < arr1.length; i++) {
				e.classList.remove(arr1[i])
			}
		})
	}

	Q_object.prototype.toggleClass = function (name) {
		var i, arr1
		arr1 = name.split(' ')
		return this.each(function (e) {
			for (i = 0; i < arr1.length; i++) {
				e.classList.toggle(arr1[i])
			}
		})
	}

	Q_object.prototype.hasClass = function (name) {
		return this.object[0].classList.contains(name)
	}

	Q_object.prototype.attr = function (name, val) {
		if (typeof val === 'undefined') {
			return this.object[0].getAttribute(name)
		} else {
			return this.each(function (e) {
				e.setAttribute(name, val)
			})
		}
	}

	Q_object.prototype.hasAttr = function (name) {
		return this.object[0].hasAttribute(name)
	}

	Q_object.prototype.removeAttr = function (name) {
		return this.each(function (e) {
			e.removeAttribute(name)
		})
	}

	Q_object.prototype.data = function (name, val) {
		if (typeof val === 'undefined') {
			return this.object[0].dataset[name]
		} else {
			return this.each(function (e) {
				e.dataset[name] = val
			})
		}
	}

	Q_object.prototype.removeData = function (name) {
		return this.removeAttr('data-' + name)
	}

	Q_object.prototype.parent = function () {
		return Q(this.object[0].parentNode)
	}

	Q_object.prototype.prev = function () {
		if (this.object[0].previousElementSibling) return Q(this.object[0].previousElementSibling)
		else return this.object[0].previousElementSibling
	}

	Q_object.prototype.next = function () {
		if (this.object[0].nextElementSibling !== null) return Q(this.object[0].nextElementSibling)
		else return this.object[0].nextElementSibling
	}

	Q_object.prototype.siblings = function () {
		var elem = this.object[0]
		var siblings = []
		var sibling = elem.parentNode.firstChild
		for (; sibling; sibling = sibling.nextSibling) {
			if (sibling.nodeType !== 1 || sibling === elem) continue
			siblings.push(sibling)
		}
		return Q(siblings)
	}

	Q_object.prototype.children = function () {
		var elem = this.object[0]
		var childrens = []

		if (elem.childNodes === undefined) {
			return
		}

		var children = elem.childNodes[0]
		for (; children; children = children.nextSibling) {
			if (children.nodeType !== 1) continue
			childrens.push(children)
		}
		return Q(childrens)
	}

	Q_object.prototype.first = function () {
		return Q(this.object[0])
	}

	Q_object.prototype.last = function () {
		return Q(this.object[this.length - 1])
	}

	Q_object.prototype.prepend = function (obj) {
		if (typeof obj !== 'string') {
			obj = obj.object[0].outerHTML
		}
		return this.each(function (e) {
			e.insertAdjacentHTML('afterbegin', obj)
		})
	}

	Q_object.prototype.append = function (obj) {
		if (typeof obj !== 'string') {
			obj = obj.object[0].outerHTML
		}
		return this.each(function (e) {
			e.insertAdjacentHTML('beforeend', obj)
		})
	}

	Q_object.prototype.before = function (obj) {
		if (typeof obj !== 'string') {
			obj = obj.object[0].outerHTML
		}
		return this.each(function (e) {
			e.insertAdjacentHTML('beforebegin', obj)
		})
	}

	Q_object.prototype.after = function (obj) {
		if (typeof obj !== 'string') {
			obj = obj.object[0].outerHTML
		}
		return this.each(function (e) {
			e.insertAdjacentHTML('afterend', obj)
		})
	}

	Q_object.prototype.clone = function (deep) {
		deep = typeof deep !== 'undefined' ? (deep === true ? true : false) : false
		return this.object[0].cloneNode(deep)
	}

	Q_object.prototype.remove = function () {
		return this.each(function (e) {
			e.parentNode.removeChild(e)
		})
	}

	Q_object.prototype.visible = function () {
		return this.object[0].offsetParent !== null
	}

	Q_object.prototype.find = function (val) {
		return Q(this.object[0].querySelectorAll(val))
	}

	Q_object.prototype.findAll = function (val) {
		return this.object[0].querySelectorAll(val)
	}

	Q_object.prototype.css = function (name, val) {
		var cssName = typeof name === 'string' ? name.toCamelCase() : ''
		if (typeof val === 'undefined') {
			if (typeof name === 'string') {
				return this.object[0].style[cssName]
			} else if (typeof name === 'object') {
				var keys = Object.keys(name)
				for (var i = 0, l = keys.length; i < l; i++) {
					if (typeof name[keys[i]] === 'string') {
						this.each(function (e) {
							e.style[keys[i].toCamelCase()] = name[keys[i]]
						})
					}
				}
			}
		} else {
			this.each(function (e) {
				e.style[cssName] = val
			})
		}
		return this
	}

	String.prototype.toCamelCase = function () {
		return this.replace(/^([A-Z])|[\s-_](\w)/g, function (match, p1, p2, offset) {
			if (p2) return p2.toUpperCase()
			return p1.toLowerCase()
		})
	}

	Q_object.prototype.text = function (val) {
		if (typeof val === 'undefined') {
			return this.object[0].textContent
		} else {
			return this.each(function (e) {
				e.textContent = val
			})
		}
	}

	Q_object.prototype.html = function (val) {
		if (typeof val === 'undefined') {
			return this.object[0].innerHTML
		} else {
			return this.each(function (e) {
				e.innerHTML = val
			})
		}
	}

	Q_object.prototype.val = function (val) {
		if (typeof val === 'undefined') {
			return this.object[0].value
		} else {
			return this.each(function (e) {
				e.value = val
			})
		}
	}

	Q_object.prototype.focus = function () {
		this.object[0].focus()
	}

	Q_object.prototype.trigger = function (ev) {
		if ('createEvent' in document) {
			var event = document.createEvent('HTMLEvents')
			event.initEvent(ev, false, true)
			this.each(function (e) {
				e.dispatchEvent(event)
			})
		} else {
			var event = document.createEventObject()
			event.eventType = ev
			this.each(function (e) {
				e.fireEvent('on' + event.eventType, event)
			})
		}
		return this
	}

	function Q(selector) {
		return new Q_object(selector)
	}

	window.q = Q
})()
