function lazyload() {
	var lazyImage = q('.lazyload')
	if (lazyImage.length > 0) {
		lazyImage.each(function (e) {
			if (elementInView(e)) {
				e = q(e)
				var srcset = e.data('srcset')
				var src = e.data('src')
				var bgsrc = e.data('bgsrc')
				if (srcset !== undefined) {
					var srcArr = srcset.split(',')
					var srcSizes = []
					for (var i = 0, j = srcArr.length - 1; i <= j; i++) {
						srcRow = srcArr[i].trim().split(' ')
						srcOne = srcRow[1].replace('w', 'px')
						if (i == 0) src = srcRow[0]
						if (i < j) srcSizes.push('(max-width: ' + srcOne + ') ' + srcOne)
						else srcSizes.push(srcOne)
					}
					e.attr('sizes', srcSizes.join(', ')).attr('srcset', srcset).removeAttr('data-srcset')
				}
				//console.log(srcset, src, bgsrc);
				if (src !== undefined) {
					e.attr('src', src).removeAttr('data-src').removeClass('lazyload').addClass('lazyloaded')
				} else if (bgsrc !== undefined) {
					e.css('background-image', 'url(' + bgsrc + ')')
						.removeAttr('data-bgsrc')
						.removeClass('lazyload')
						.addClass('lazyloaded')
				}
			}
		})
	}
}

function elementInView(e) {
	var rect = getCoordinates(q(e))
	/*
if (q(e).hasClass('gallery-grid-img')) {
	console.log(e);
	console.log(rect);
}
*/
	return (
		// (
		// 	rect.top >= 0 &&
		// 	rect.left >= 0 &&
		// 	rect.bottom <= rect.window.height &&
		// 	rect.right <= rect.window.width
		// ) || (
		// 	rect.height >= rect.window.height &&
		// 	(
		(rect.top >= 0 && rect.top <= rect.window.height) || (rect.bottom >= 0 && rect.bottom <= rect.window.height)
		// 	)
		// )
	)

	/*
	return (
		rect.top >= 0 && rect.left >= 0 &&
		rect.bottom <= rect.window.height && rect.right <= rect.window.width
	);
*/
}
