/**************************************************************************************************/

function initForm() {
	var $formField = q(
		'input:not([type="checkbox"]):not([type="hidden"]), textarea'
	);

	$formField.each(function (a) {
		if (q(a).val() != '') {
			q(a).addClass('has-value');
		}
	});

	$formField.on('focus', function () {
		q(this).addClass('has-value');
	});

	$formField.on('blur', function () {
		var $formFieldParent = q(this).parent();
		if (q(this).val() == '') {
			q(this).removeClass('has-value');
		}
	});
}

/**************************************************************************************************/


/**
 * Set Variable and Callbacks for Recaptcha V2
 */

var recaptchaChecked = true;
console.log('captcha deactivated'); 

function recaptchaSuccess() {
	recaptchaChecked = true
}

function recapatchaExpired() {
	recaptchaChecked = false
}

function replaceValidationUI() {
	var form = q('.kontakt-form form');

	form.on('invalid', function (e) {
		e.preventDefault();
	});

	form.on('submit', function (e) {
		if (!this.checkValidity()) {
			e.preventDefault();
		}
	});

	form.find('button[type=submit]').on('click', function (e) {
		e.preventDefault();
		var fieldError = [];

		form.find('input, textarea').each(function (e) {
			var field = q(e);
			var uniqID = (Math.random() + 1)
				.toString(36)
				.replace(/[^a-z]+/g, '')
				.substr(0, 5);
			var tmpUniqID = field.data('uniqid');
			var isVisible = field.visible();
			if (isVisible && !e.validity.valid) {
				field.addClass('error');
				if (typeof tmpUniqID === 'undefined') {
					field
						.data('uniqid', uniqID)
						.parent()
						.append(
							'<div id=' +
							uniqID +
							' class="field-error">' +
							field.object[0].validationMessage +
							'</div>'
						);
				} else {
					q('#' + tmpUniqID).html(field.object[0].validationMessage);
				}
				fieldError.push(q(e));
			} else {
				if (typeof tmpUniqID === 'string') {
					q('#' + field.data('uniqid')).remove();
					field.removeClass('error').removeData('uniqid');
				}
			}
		});


		recaptchaMessage = document.querySelector('.kontakt-form .recaptcha-message');

		if (recaptchaMessage && !recaptchaChecked) {
			recaptchaMessage.innerHTML = '<div class="field-error">Bitte zu unserem Spamschutz das Recaptcha-Feld bestätigen</div>';
		}

		if (fieldError.length == 0 && recaptchaChecked) {

			const formConcern = [];
			const formConcernType = [];

			// get input values

			if (document.querySelectorAll("input:checked[name='Art_der_Beratung[]']").length !== 0) {
				formConcern.push(`Beratung`);
			}

			if (document.querySelectorAll("input:checked[name='Broschren_PRIVAT[]']").length !== 0) {
				formConcern.push(`Broschüre Privat`);
			}

			if (document.querySelectorAll("input:checked[name='Broschren_HOTEL[]']").length !== 0) {
				formConcern.push(`Broschüre Hotel`);
			}

			//formConcern

			document.querySelectorAll("input:checked[name='Broschren_HOTEL[]']").forEach(element => {
				formConcernType.push(`Broschüre Hotel: ${element.value}`);
			});;

			document.querySelectorAll("input:checked[name='Broschren_PRIVAT[]']").forEach(element => {
				formConcernType.push(`Broschüre Privat: ${element.value}`);
			});;

			document.querySelectorAll("input:checked[name='Art_der_Beratung[]']").forEach(element => {
				formConcernType.push(`Beratung: ${element.value}`);
			});;



			if (document.querySelectorAll("input:checked[name='newsletter[]']").length !== 0) {
				if (typeof dataLayer != 'undefined') {
					dataLayer.push({
						event: 'newsletterSubscribed',
						newsletterSubscription: 'Angemeldet'
					})
				}
			}



			//add to datalayer
			if (typeof dataLayer != 'undefined') {
				dataLayer.push({
					event: 'contactFormSubmit',
					formConcern: formConcern,
					formConcernType: formConcernType,
				})
			} else {
				console.log('datalayer undefined')
			}

			form.object[0].submit();
			return true;

		} else {
			if (fieldError[0]) {
				var coordinate = getCoordinates(fieldError[0]);
				q('html').object[0].scrollTop =
					coordinate.window['pageYOffset'] + coordinate.top - 50;
				fieldError[0].focus();
			}
			return false;

		}
	});
}

/**************************************************************************************************/

