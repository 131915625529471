function initListAccordion() {
	if (q('.project-list-accordion').length > 0) {
		q('.card-accordion-header').on('click', function () {
			q(this).toggleClass('open')

			if (q(this).hasClass('open')) {
				var e = q(this).get(0)
				wait(0.3, function () {
					scrollTo(document.documentElement, getPosition(e)['top'], 1000)
				})
			}
		})
	}
}

// hint: run visibilitycheck every time a checkbox is clicked,
// conditional fields are really messing with the form
// FIXME: improve conditional fields

function initKontaktForm() {
	if (q('.kontakt-form').length > 0) {

		q('input[required] + label, input.conditionally-required + label').after(
			'<span class="input-required">*</span>'
		)
		q('.kontakt-form input[required], .kontakt-form input.conditionally-required')
			.data('req', true)
			.each(function (e) {
				e.required = false
			})

		var g1 = q('.kontakt-form-g1 input[type=checkbox]')
		//		q('.f1, .f2, .f3').css('display', 'none');

		replaceValidationUI()
		replaceRequierdAttribute()

		g1.on('click', function (e) {
			var f1 = false,
				f2 = false,
				f3 = false,
				f4 = false,
				f5 = false,
				f6 = false,
				isChecked = false
			g1.each(function (e) {
				f1 = e.checked && e.id.includes('welcheberatung') ? true : f1
				f2 = e.checked && e.id.includes('BroschreWhlenOptionen') ? true : f2
				f3 = e.checked && e.id == 'help_option2' ? true : f3
				f4 = e.checked && e.id == 'help_option0' ? true : f4
				f5 = e.checked && e.id == 'welcheberatung0' ? true : f5
				f6 = e.checked && e.id == 'help_option1' ? true : f6
				isChecked = f1 || f2 || f3
			})

			if (f4) {
				q('.f4').addClass('open')
			} else {
				q('.f4').removeClass('open')
			}

			if (f5) {
				q('.f5').addClass('open')
			} else {
				q('.f5').removeClass('open')
			}

			if (f6) {
				q('.f6').addClass('open')
			} else {
				q('.f6').removeClass('open')
			}

			if (isChecked) {
				q('.kontakt-form-g2, .kontakt-form-g3').addClass('open')
				q('.f1, .f2, .f3').removeClass('open')
				q('.kontakt-form input[required], .kontakt-form input.conditionally-required').each(function (e) {
					setTimeout(() => {
						if (!q(e).visible()) { 
							e.required = false
						}
					});
				})

				if (f1) q('.f1').addClass('open')
				if (f2) q('.f2').addClass('open')
				if (f3) q('.f3').addClass('open')


				replaceRequierdAttribute()


			} else {
				q('.kontakt-form-g2, .kontakt-form-g3').removeClass('open')
				q('.f1, .f2, .f3').removeClass('open')
				q('.kontakt-form input[required], .kontakt-form input.conditionally-required').each(function (e) {
					setTimeout(() => {

						if (!q(e).visible()) {
							e.required = false
						}
					});
				})
			}
		})
	}
}

function replaceRequierdAttribute() {
	q('.kontakt-form .form-control').each(function (e) {
		var obj = q(e)
		var vis = obj.visible()
		var req = obj.data('req')

		if (typeof req === 'string' && vis === true) {
			obj.attr('required', '')
		}
	})
}

function initLoadFilterMore() {
	q('#project-list-load-more').on('click', function (e) {
		const currentProjectList = this.closest('.project-list-wrapper')
		filterResultToLoad = q('.project-list-toLoad').html()
		if (filterResultToLoad > 0) {
			var filterArray = []

			if (filter) {
				var filterObject = JSON.parse(filter)

				for (var filterObjectId in filterObject) {
					filterArray.push(filterObjectId)
				}
			}

			var params = {}
			params[CRAFT_CSRF_TOKEN_NAME] = CRAFT_CSRF_TOKEN
			params['page'] = ++filterResultPage
			params['filter'] = JSON.stringify(filterArray)
			params['type'] = 'filter'
			params['limit'] = limit

			ajax.post(filterPage, params, function (data) {
				q(currentProjectList).find('.project-list').last().after(data)

				filterResultToLoad -= q(currentProjectList)
					.find('.project-list')
					.last()
					.addClass('filter-result-load open')
					.find('.project-teaser').length

				filterResultToLoad = Math.max(filterResultToLoad, 0)

				var t = q('.project-list-toLoad').html(filterResultToLoad)
				if (filterResultToLoad <= 0) {
					q('.project-list-more').css('display', 'none')
				}
			})
		}
	})
}

function initLoadSearchMore() {
	q('#search-teaser-load-more').on('click', function (e) {
		searchResultToLoad = q('.search-teaser-toLoad').html()
		if (searchResultToLoad > 0) {
			var params = {}
			params[CRAFT_CSRF_TOKEN_NAME] = CRAFT_CSRF_TOKEN
			params['page'] = ++searchResultPage
			params['q'] = searchQ
			params['type'] = 'search'
			params['limit'] = limitQ

			ajax.post(searchPage, params, function (data) {
				var div = document.createElement('div')
				div.innerHTML = data
				div = q(div)
				searchResultToLoad -= div.children().length
				searchResultToLoad = Math.max(searchResultToLoad, 0)
				div.addClass('search-teaser-load')
				q('.search-teaser-more').before(div)
				q('.search-teaser-load').addClass('open')
				q('.search-teaser-toLoad').html(searchResultToLoad)
				if (searchResultToLoad <= 0) {
					q('.search-teaser-more').css('display', 'none')
				}
			})
		}
	})
}

function initModalSearch() {
	q('a.nav-search, a.modal-search-close').on('click', function (e) {
		e.preventDefault()
		q('.modal-search').toggleClass('visible')
		if (q('.modal-search').hasClass('visible')) {
			q('html').addClass('frozen')
			q('.form-control.search').val('').focus()
		} else {
			q('html').removeClass('frozen')
		}
		return false
	})

	var btnSearch = q('button.btn-search-clear')

	q('input.form-control.search').on('keydown', function () {
		var l = q(this).val().length
		if (l > 0) {
			btnSearch.css('display', 'inline-block')
		} else {
			btnSearch.css('display', '')
		}
	})

	q('button.btn-search-clear').on('click', function () {
		q(this).prev().val('').focus()
		btnSearch.css('display', '')
	})
}

function initModalMenu() {
	q('a.nav-burger').on('click', function (e) {
		e.preventDefault()
		q('.modal-menu').toggleClass('visible')
		q('.navbar:not(.modal-close)').toggleClass('d-none')
		q('.modal-close').toggleClass('d-none')
		q('#header .navbar-switch').toggleClass('d-none')
		q('html').toggleClass('modal-open')
		return false
	})

	q('a.modal-menu-link').on('click', function (e) {
		if (q(this).attr('href') === '#') {
			e.preventDefault()
		}
		var allSubgroups = q(this).parent().siblings(),
			mySubgroup = q(this).next()

		if (allSubgroups.length > 0) {
			allSubgroups.each(function (e) {
				if (q(e).hasClass('open')) {
					q(e).removeClass('open').find('.open').removeClass('open')
				}
			})
		}
		if (typeof mySubgroup !== 'undefined') {
			mySubgroup.toggleClass('open').parent().toggleClass('open')
			mySubgroup.prev().trigger('blur')
		}
		return false
	})
}

function initSlider(className, imgResponsive, autoPlay = false) {
	var c = q('.' + className)
	if (c.length > 0) {
		if (typeof imgResponsive !== 'undefined' && imgResponsive === true) {
			sliderImgResponsive(className)
		}
		c.each(function (e, i) {
			if (q(e).children().length > 1) {
				var idName = className + '-' + i
				q(e).attr('id', idName)
				var slider = tns({
					container: '#' + idName,
					items: 1,
					controls: true,
					autoplay: autoPlay,
					controlsText: [' ', ' '],
					slideBy: 'page',
					mouseDrag: true,
					swipeAngle: 45,
					preventScrollOnTouch: 'auto',
					speed: 400,
				})
				sliders.push(slider)

				var nav = q('#' + idName + '-ow').find('.tns-nav')
				var navButtons = nav.children()
				// navButtons.each(function (e) {
				// 	e.textContent = '0' + (parseInt(q(e).data('nav')) + 1);
				// });
				nav.css('min-width', navButtons.length * 44 + 'px')
			}
		})
	}
}
//window.innerWidth >= galleryBreak
function sliderImgResponsive(className) {
	className = typeof className !== 'undefined' ? className : 'carousel'
	var c = q('.' + className)
	if (c.length > 0) {
		var imgSize = (true ? 'srcD' : 'srcM').toLowerCase()
		c.children().each(function (e) {
			var img = q(e.children[0])
			if (typeof img === 'object' && img.data(imgSize) !== 'undefined') {
				img.attr('src', img.data(imgSize))
			}
		})
	}
}

function initTeaserSlider(className, control) {
	className = typeof className !== 'undefined' ? className : 'carousel-teaser'
	control = typeof control !== 'undefined' ? control : false
	var c = q('.' + className)
	if (c.length > 0) {
		c.each(function (e, i) {
			if (q(e).children().length > 1) {
				var idName = className + '-' + i
				q(e).attr('id', idName)
				var slider = tns({
					container: '#' + idName,
					items: 1.1,
					loop: false,
					controls: false,
					mouseDrag: true,
					swipeAngle: 45,
					autoplay: false,
					speed: 400,
					gutter: 12,
					preventScrollOnTouch: 'auto',
					responsive: {
						'800': {
							items: 1.8,
							gutter: 25,
						},
						'1400': {
							items: 2.28,
							gutter: 38,
							controls: control,
							controlsText: ['', ''],
						},
					},
				})
				sliders.push(slider)

				var nav = q('#' + idName + '-ow').find('.tns-nav')
				var navButtons = nav.children()
				// navButtons.each(function (e) {
				// 	e.textContent = '';
				// });
				nav.css('min-width', navButtons.length * 44 + 'px')
			}
		})
	}
}

function initImages3Slider(className) {
	className = typeof className !== 'undefined' ? className : 'carousel-images3'
	var c = q('.' + className)
	if (c.length > 0) {
		c.each(function (e, i) {
			if (q(e).children().length > 1) {
				var idName = className + '-' + i
				q(e).attr('id', idName)
				var slider = tns({
					container: '#' + idName,
					// slideBy: 1,
					loop: false,
					controls: false,
					mouseDrag: true,
					swipeAngle: 45,
					speed: 400,
					preventScrollOnTouch: 'auto',
					gutter: 12,
					autoWidth: true,
					responsive: {
						'768': {
							autoWidth: true,
							gutter: 18,
						},
						'1400': {
							autoWidth: true,
							gutter: 24,
						},
					},
				})

				sliders.push(slider)

				var nav = q('#' + idName + '-ow').find('.tns-nav')
				var navButtons = nav.children()
				// navButtons.each(function (e) {
				// 	e.textContent = '0' + (parseInt(q(e).data('nav')) + 1);
				// });
				// nav.css('min-width', navButtons.length * 44 + 'px');
				// q('#' + idName + ' > .tns-item').css('width', 'auto');
			}
		})
	}
}

function initTeamSlider(className) {
	className = typeof className !== 'undefined' ? className : 'carousel-team'
	var c = q('.' + className)
	if (c.length > 0) {
		c.each(function (e, i) {
			if (q(e).children().length > 1) {
				var idName = className + '-' + i
				q(e).attr('id', idName)
				var slider = tns({
					container: '#' + idName,
					//					  "autoWidth": true,
					items: 1.1,
					slideBy: 1,
					loop: false,
					controls: true,
					mouseDrag: true,
					controlsText: ['', ''],
					swipeAngle: 45,
					speed: 400,
					gutter: 12,
					preventScrollOnTouch: 'auto',
					controlsText: ['', ''],
					responsive: {
						'800': {
							items: 2.5,
							gutter: 24,
						},
						'1400': {
							items: 2.5,
							gutter: 40,
						},
					},
				})
				sliders.push(slider)

				var nav = q('#' + idName + '-ow').find('.tns-nav')
				var navButtons = nav.children()
				// navButtons.each(function (e) {
				// 	e.textContent = '0' + (parseInt(q(e).data('nav')) + 1);
				// });
				nav.css('min-width', navButtons.length * 44 + 'px')
			}
		})
	}
}

function teamSliderNav(className) {
	className = typeof className !== 'undefined' ? className : 'carousel-team'
	var c = q('.' + className)
	if (c.length > 0) {
		c.each(function (e, i) {
			var idName = '#' + className + '-' + i + '-ow'
			var nav = q(idName).find('.tns-nav')
			var navButtons = nav.children()
			var rect = navButtons.object[0].getBoundingClientRect()
			var visible = 0
			navButtons.each(function (e) {
				if (q(e).visible()) visible++
			})
			nav.css('min-width', visible * (rect.width + 4) + 'px')
		})
	}
}

var filterOpen = false
var filterBtnOn = false
var filterPlace = 'XS'
var filterOption = []
var runFunction = (prevFunction = initFilterOpenLG)

function initFilter(preventCount = false) {
	var filterBtn = q('button.btn-filter')

	if (filterBtn.length > 0) {
		runFunction = pageWidth >= pageBreak ? initFilterOpenLG : initFilterOpenXS

		var checkFilter = q('.filter-check-item > input.ghost')

		if (!preventCount) {
			checkFilter
				.on('click', function () {
					checkFilterCounter(this, -1)
				})
				.each(function (e) {
					checkFilterCounter(e, 0)
				})
		}

		if (prevFunction != runFunction) {
			if (filterOpen) {
				prevFunction()
			}
			filterBtn.off('click', prevFunction)
			filterBtn.on('click', runFunction)
			prevFunction = runFunction
			filterBtnOn = true
			q('.btn-filter-count-left')
				.html
				// q('.btn-filter-count-left').data('label')
				()
			q('.btn-filter-count-right')
				.html
				// q('.btn-filter-count-right').data('label')
				()
		}

		if (filterBtnOn === false) {
			filterBtn.on('click', runFunction)
			filterBtnOn = true
		}
	}
}

function initFilterOpenXS() {
	if (filterPlace != 'XS') {
		q('.btn-filter-left').after(q('.filter-check-left').remove())
		q('.btn-filter-right').after(q('.filter-check-right').remove())
		q(document).on('click', 'input.ghost', function () {
			checkFilterCounter(this, -1)
		})
		filterPlace = 'XS'
	}

	var filterButtons = q('button.btn-filter')
	var filterBtn = q(this)

	for (var i = 0; i < filterButtons.length; i++) {
		if (filterButtons.object[i] != filterBtn.object[0]) {
			q(filterButtons.object[i]).removeClass('overFilter')
		}
	}

	filterBtn.toggleClass('overFilter')
}

function initFilterOpenLG() {
	if (filterPlace != 'LG') {
		q('.filter-place-left').append(q('.filter-check-left').remove())
		q('.filter-place-right').append(q('.filter-check-right').remove())
		q(document).on('click', 'input.ghost', function () {
			checkFilterCounter(this, -1)
		})
		filterPlace = 'LG'
	}

	var filterBtn = q('button.btn-filter')
	var filterSubmit = q('button.btn-filter-submit')
	var filterMenu = q('.filter-menu')

	filterOpen = !filterOpen

	positionFilter()

	if (filterOpen) {
		filterMenu.addClass('open')
		filterBtn.addClass('overFilter')
		filterSubmit.addClass('overFilter')
	} else {
		if (filterMenu.hasClass('open')) {
			filterMenu.addClass('close')
			wait(0.2, function () {
				filterMenu.removeClass('open close')
				filterBtn.removeClass('overFilter')
				filterSubmit.removeClass('overFilter')
			})
		}
	}
}

function positionFilter() {
	if (filterOpen) {
		var leftBtn = getCoordinates(q('button.btn-filter').first())
		var rightBtn = getCoordinates(q('button.btn-filter').last())
		q('.filter-menu').css({
			'margin-left': 'calc(' + leftBtn.left + 'px - 2vw)',
			width: 'calc(' + (rightBtn.right - leftBtn.left) + 'px + 4vw)',
		})
	}
}

function checkFilterCounter(obj, ref) {
	ref = typeof ref !== 'undefined' ? ref : 0
	var qObj = q(obj)
	var listToCount = q('.' + qObj.data('list'))
	var listCounter = parseInt(listToCount.html())
	var numToAdd = qObj.object[0].checked ? 1 : ref

	listCounter = (listCounter > 0 ? listCounter : 0) + numToAdd

	if (listCounter > 0) {
		listToCount.html(listCounter)
	} else {
		listToCount.html(listToCount.data('label'))
	}

	if (ref < 0) {
		getFilterCalc()
	}
}

function getFilterCalc() {
	var f = q('.filter-check-item > input[type=checkbox]')
	filterOption = []
	f.each(function (e) {
		if (e.checked) {
			filterOption.push(e.value)
		}
	})

	var params = {}
	params[CRAFT_CSRF_TOKEN_NAME] = CRAFT_CSRF_TOKEN
	params['filterCountPage'] = JSON.stringify(filterOption)
	params['type'] = 'filterCount'

	ajax.post(filterCountPage, params, function (data) {
		q('.filter-counters > span').html(data)
	})
}

// function initProjectPlanning() {
// 	var obj = q('.card.project-planning')
// 	if (obj.length > 0) {
// 		//		var projectImgH = q(obj.object[0]).parent().parent().find('.card.project-teaser').first().find('img').object[0].height;
// 		var projectImgH = q(obj.object[0]).parent().prev().find('.card.project-teaser').first().find('img').object[0]
// 			.height
// 		var css = pageBreakCheck ? 'calc(' + projectImgH + 'px - 3rem)' : ''
// 		obj.each(function (e) {
// 			q(e).css('height', css)
// 		})
// 	}
// }
