// @koala-prepend "../vendor/tiny-slider-master/dist/tiny-slider.js"
// @koala-prepend "functions.js"
// @koala-prepend "slice.js"


/**************************************************************************************************/

//reload on reCAPTCHA V1/V2 acceptance
// if (uc !== undefined) {
// 	uc.reloadOnOptIn("Hko_qNsui-Q")
// }
/**************************************************************************************************/

var pageFromTop = 250
var pageFromBottom = 250
var pageOrientation = pageSize()
var pageWidth = window.innerWidth
var pageBreak = 600
var galleryBreak = 800
var sliders = []
var pageBreakCheck = pageWidth >= pageBreak
var galleryBreakCheck = pageWidth >= galleryBreak
var searchResultPage = 1
var searchResultToLoad = 0
var filterResultPage = 1
var filterResultToLoad = 0

assetsPath = typeof assetsPath !== 'undefined' ? assetsPath : ''

const imagePaneSelector = '.card-img--pane'

const selectorsForFadeAnimation = [
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'#footer .logo',
	'.img-text-left img',
	'.teaser-right img',
	'.gallery-grid img',
]

/**************************************************************************************************/

q(document).on('DOMContentLoaded', function () {
	initForm()
	initKontaktForm()
	initModalMenu()
	initModalSearch()
	initFilter()
	initLoadFilterMore()
	initSlider('carousel', true, true)
	initSlider('gallery-carousel', false)
	initSlider('full-gallery-slider', false)

	initTeaserSlider('carousel-teaser', true)
	initTeaserSlider('carousel-news', true)
	initImages3Slider()
	initTeamSlider()
	initListAccordion()
	teamSliderNav()

	initLoadSearchMore()

	ajax.send(assetsPath + '/assets/img/svg/arrow-btn.svg', function (data) {
		var span = document.createElement('span')
		span.innerHTML = data
		q('.btn.btn-ghost').each(function (e) {
			e.insertBefore(span.firstChild.cloneNode(true), e.firstChild)
		})
	})

	q('body')
		.addClass('browser-' + BrowserDetect.browser)
		.addClass('page-' + pageOrientation)

	//startImagePane(imagePaneSelector);

	var viewportWidth = window.innerWidth || document.documentElement.clientWidth

	startTextFadeIn(selectorsForFadeAnimation)
	// if (viewportWidth > 768) {
	//

	// 	startParallax(['.teaser-right-image'], 'parallax--back', {
	// 		speed: -1.5,
	// 		center: true,
	// 		wrapper: null,
	// 		round: true,
	// 		vertical: true,
	// 		horizontal: false
	// 	});

	// 	startParallax(
	// 		['.img-text-left-content', '.stage .stage-title-container'],
	// 		'parallax--front',
	// 		{
	// 			speed: 3,
	// 			center: true,
	// 			wrapper: null,
	// 			round: true,
	// 			vertical: true,
	// 			horizontal: false
	// 		}
	// 	);
	// }
})

/**************************************************************************************************/

q(window).on('load', function () {
	lazyload()
	// initProjectPlanning()
})

/**************************************************************************************************/

// window.addEventListener(
// 	'scroll',
// 	() => {
// 		lazyload()
// 		// initProjectPlanning()
// 	},
// 	{ passive: true }
// )



/*
	Intersection Observer
*/
const observer = new IntersectionObserver((entries, observer) => {
	entries.forEach((entry) => {
		if (!entry.isIntersecting) { return }

		entry.target.querySelectorAll('source').forEach((v) => {
			if (v.dataset.src) {

				v.srcset = v.dataset.src
				observer.unobserve(v)
			}
		})


	})
})

document.querySelectorAll('picture.lazyload').forEach((v) => {
	observer.observe(v)
})

navigatonBarScrolling()

/**************************************************************************************************/

q(window).on('resize', function () {
	pageWidth = window.innerWidth

	var pageReSized = pageSize()

	if (pageOrientation != pageReSized) {
		q('body').removeClass('page-' + pageOrientation)
		pageOrientation = pageReSized
		q('body').addClass('page-' + pageOrientation)
	}

	if (galleryBreakCheck !== pageWidth >= galleryBreak) {
		galleryBreakCheck = pageWidth >= galleryBreak
		sliderImgResponsive('carousel')
		teamSliderNav()
	}

	var pageBreakChanged = pageWidth >= pageBreak
	if (pageBreakCheck !== pageBreakChanged) {
		initFilter(true)
		pageBreakCheck = pageBreakChanged
	}
	if (pageBreakCheck) {
		positionFilter()
	}

	// initProjectPlanning()
})

/**************************************************************************************************/
